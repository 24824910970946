<template>
  <page-title class="q-mb-lg" :title="$t('account.change_email')" />
  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-up"
  >
    <div
      class="q-pa-md q-mr-md q-ml-md bg-grey-3 q-mt-md q-mb-lg default-rounded"
    >
      <p class="text-justify" v-html="$t('account.contact_details_message')" />
      <p
        class="text-justify q-pb-none q-mb-none"
        v-html="
          $t('account.change_email_message', { value: 'suporte@flip.net.br' })
        "
      />
    </div>

    <div class="q-mr-md q-ml-md" v-if="in_request">
      <div class="q-mb-md" v-for="(field, i) in partnerData" :key="i">
        <field-to-validate-component
          v-if="field.isValidate"
          :field="field"
          @verifyOrChange="setAction($event)"
        />

        <FieldToUpdateComponent
          v-else
          :field="field"
          @verifyOrChange="setAction($event)"
        />
      </div>
      <q-checkbox v-model="opt_in" :label="$t('global.opt_in_terms')" />
    </div>
    <div class="q-mr-md q-ml-md" v-else>
      <contact-details-skeleton />
    </div>
  </q-intersection>

  <ValidateOrChangeEmail
    v-if="showEmailModal"
    v-model="showEmailModal"
    :data="dataToValidateOrVerify"
    @changeOrVerify="showEmailModal = false"
  />

  <ValidateOrChangeCelular
    v-if="showCelularModal"
    v-model="showCelularModal"
    :data="dataToValidateOrVerify"
    @changeOrVerify="showCelularModal = false"
  />
</template>

<script>
import FieldToValidateComponent from "./verification-components/FieldToValidateComponent.vue";
import FieldToUpdateComponent from "./verification-components/FieldToUpdateComponent.vue";
import ValidateOrChangeEmail from "./verification-components/ValidateOrChangeEmail.vue";
import ValidateOrChangeCelular from "./verification-components/ValidateOrChangeCelular.vue";
import ContactDetailsSkeleton from "./skeletons/ContactDetailsSkeleton.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { defineComponent, onMounted, ref, watch } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("account");

export default defineComponent({
  name: "contactDetails",

  components: {
    PageTitle,
    ValidateOrChangeEmail,
    ValidateOrChangeCelular,
    FieldToValidateComponent,
    FieldToUpdateComponent,
    ContactDetailsSkeleton,
  },

  setup() {
    const dataToValidateOrVerify = ref({}),
      in_request = ref(false),
      showEmailModal = ref(false),
      showCelularModal = ref(false);

    const opt_in = ref(false);
    const { fetchPartnerChallenges, updateUserPartnerAction } = useActions([
        "fetchPartnerChallenges",
        "updateUserPartnerAction",
      ]),
      { partnerData, getUserAccount } = useGetters({
        partnerData: "getPartnerChallenges",
        getUserAccount: "getUserAccount",
      });

    onMounted(() => {
      fetchPartnerChallenges().finally(() => (in_request.value = true));
      if (getUserAccount.value)
        opt_in.value = getUserAccount.value.partner.opt_in == 1;
    });

    watch(opt_in, (value) => {
      updateUserPartnerAction({
        opt_in: value ? 1 : 0,
      });
    });

    return {
      opt_in,
      in_request,
      partnerData,
      showEmailModal,
      showCelularModal,
      dataToValidateOrVerify,
      getUserAccount,
      setAction: (data) => {
        dataToValidateOrVerify.value = data;
        if (data.data.type === "celular") showCelularModal.value = true;
        if (data.data.type === "email") showEmailModal.value = true;
      },
    };
  },
});
</script>
