<template>
  <q-input
    filled
    disable
    :label="field.label"
    :mask="field.mask"
    :model-value="field.value"
  >
    <template v-slot:prepend>
      <q-icon :name="field.icon" />
    </template>
    <template v-slot:after v-if="$q.screen.width > 800">
      <q-btn
        label="Verificar"
        class="q-pa-md"
        :loading="in_request"
        v-if="!field.validated"
        @click="
          verifyOrChange('verify', {
            type: field.type,
            value: field.value,
            validated: field.validated,
          })
        "
      />
      <q-btn
        label="Alterar"
        color="white"
        :disabled="field.type != 'celular' ? !field.validated : false"
        class="bg-black q-pa-md q-ml-sm"
        @click="
          verifyOrChange('change', {
            type: field.type,
            value: field.value,
            validated: field.validated,
          })
        "
      />
    </template>
  </q-input>

  <q-badge
    class="q-mt-sm q-pa-sm tex-weight-bold"
    :class="{
      'bg-red': !field.validated,
      'bg-green': field.validated,
    }"
    :label="field.validated_at"
  />
  <div v-if="$q.screen.width <= 800" align="right" class="q-mt-sm">
    <q-btn
      label="Verificar"
      class="q-pa-md"
      :loading="in_request"
      v-if="!field.validated"
      @click="
        verifyOrChange('verify', {
          type: field.type,
          value: field.value,
          validated: field.validated,
        })
      "
    />
    <q-btn
      label="Alterar"
      color="white"
      :disabled="field.type != 'celular' ? !field.validated : false"
      class="bg-black q-pa-md q-ml-sm"
      @click="
        verifyOrChange('change', {
          type: field.type,
          value: field.value,
          validated: field.validated,
        })
      "
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useActions } = createNamespacedHelpers("account");
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "FieldToValidateComponent",

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  emits: ["verifyOrChange"],

  setup(props, { emit }) {
    const in_request = ref(false);

    const { fetchSendChallenge } = useActions(["fetchSendChallenge"]);

    const verifyOrChange = async (type, data) => {
      if (type == "verify") {
        in_request.value = true;
        await fetchSendChallenge({
          type: data.type == "celular" ? "sms" : data.type,
        }).finally(() => (in_request.value = false));
      }

      emit("verifyOrChange", {
        action: type,
        data,
      });
    };

    return { in_request, verifyOrChange };
  },
});
</script>
